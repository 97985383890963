import React, { useState } from 'react';
import emailjs from 'emailjs-com';

export default function ContactMe() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    topic: '',
    message: ''
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const sendEmail = (e) => {
    e.preventDefault();

    // EmailJS form submission
    emailjs.sendForm('service_s2m032s', 'template_ujidckq', e.target, 'EmlLzrSwmvN39P7Qt')
      .then((result) => {
          console.log(result.text);
          alert('Mesaj başarıyla gönderildi!');
      }, (error) => {
          console.log(error.text);
          alert('Mesaj gönderimi sırasında bir hata oluştu.');
      });
  };

  return (
    <section id="Contact" className="contact--section">
      <div>
        <h2>Neta Soft İle Temasa Geçin</h2>
        <p className="text-lg">
          İş fikirleriniz, istekleriniz ve önerileriniz için bizlerle temasa geçebilirsiniz.
        </p>
      </div>
      <form className="contact--form--container" onSubmit={sendEmail}>
        <div className="container">
          <label htmlFor="first-name" className="contact--label">
            <span className="text-md">Ad</span>
            <input
              type="text"
              className="contact--input text-md"
              name="firstName"
              id="first-name"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="last-name" className="contact--label">
            <span className="text-md">Soyad</span>
            <input
              type="text"
              className="contact--input text-md"
              name="lastName"
              id="last-name"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="email" className="contact--label">
            <span className="text-md">Email</span>
            <input
              type="email"
              className="contact--input text-md"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label htmlFor="phone-number" className="contact--label">
            <span className="text-md">Telefon Numarası</span>
            <input
              type="number"
              className="contact--input text-md"
              name="phoneNumber"
              id="phone-number"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <label htmlFor="choose-topic" className="contact--label">
          <span className="text-md">Konu</span>
          <select
            id="choose-topic"
            className="contact--input text-md"
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            required
          >
            <option>İletişime Geçmek İstediğiniz Konu</option>
            <option>Web Tasarımı</option>
            <option>Sosyal Medya Yönetimi</option>
            <option>Reklam Yönetimi</option>
            <option>Diğer</option>
          </select>
        </label>
        <label htmlFor="message" className="contact--label">
          <span className="text-md">Mesaj</span>
          <textarea
            className="contact--input text-md"
            id="message"
            name="message"
            rows="8"
            value={formData.message}
            onChange={handleChange}
            placeholder="İletmek istediğiniz mesaj"
            required
          />
        </label>
        <label htmlFor="checkbox" className="checkbox--label">
          <input
            type="checkbox"
            required
            name="checkbox"
            id="checkbox"
          />
          <span className="text-sm">Tüm şartları kabul ediyorum.</span>
        </label>
        <div>
          <button className="btn btn-primary contact--form--btn" type="submit">Gönder</button>
        </div>
      </form>
    </section>
  );
}

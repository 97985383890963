export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      {/* <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">Neta Soft'a Hoşgeldin!</p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Yazılım ve</span>{" "}
            <br />
            Sosyal Medya
          </h1>
          <p className="hero--section-description">
            Profesyonel WEB tasarımlarımız ve sosyal medya danışmanlık hizmetimizden faydalanmak ister misiniz?

            <br />  Markanızı birlikte güçlendirelim.
          </p>
        </div>
        <button className="btn btn-primary"><a id="more-info"
          href="https://wa.me/905551510216?text=Merhaba!Bilgi%20alabilir%20miyim?"
          target="_blank"
        >DAHA FAZLA BİLGİ AL</a></button>
      </div> */}
      <div className="bg-video">
      <video autoPlay loop muted playsInline preload="auto">
        <source src="/img/videoweb.mp4" type="video/mp4"/>
        <source src="/img/videoweb.webm" type="video/webm"/>
      </video>

      

      </div>
    </section>
  );
}

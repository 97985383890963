export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/logo.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">Neta Soft</p>
          <h1 className="skills-section--heading">Hakkımızda</h1>
          <p className="hero--section-description">
          2023'ün Eylül ayında yola çıkan genç ve dinamik ekibimizle, Doğu Anadolu'nun dijital dönüşümüne öncülük ediyoruz.  Yazılım çözümleri ve sosyal medya yönetimi alanındaki uzmanlığımızla, işletmelerinizi dijital dünyada daha görünür kılıyoruz. Google ve Meta reklamlarınızın en verimli şekilde yönetilmesi için özel stratejiler geliştiriyoruz.  Hedefimiz, Doğu Anadolu'nun ardından Türkiye genelinde tüm işletmelere ulaşarak, onların büyümelerine katkı sağlamak. Siz de dijital pazarlamada fark yaratmak istiyorsanız, hemen bizimle iletişime geçin.
          </p>
        </div>
      </div>
    </section>
  );
}

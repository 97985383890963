import data from "../../data/index.json";

export default function Testimonial() {
  return (
    <section id="price" class="price-section">
    <h2 class="section-title">Fiyatlandırma Planları</h2>
    <div class="price-plans">
      <div class="price-card">
        <h3 class="plan-title">Bronz Paket</h3>
        <p class="price">5500₺</p>
        <ul class="features">
          <li>1 Adet Reels</li>
          <li>15 Hikaye Tasarımı</li>
          <li>3 Post Tasarımı</li>
          <li>Özel Gün Paylaşımı</li>
          <li>15 Gün Hizmet Süresi</li>
        </ul>
        <button class="btn">Şimdi Al</button>
      </div>
      <div class="price-card">
        <h3 class="plan-title">Demir Paket</h3>
        <p class="price">8500₺</p>
        <ul class="features">
          <li>3 Adet Reels</li>
          <li>25 Adet Hikaye Tasarımı</li>
          <li>6 Post Tasarımı</li>
          <li>Reklam Yönetimi</li>
          <li>Özel Gün Paylaşımları</li>
          <li>30 Gün Hizmet Süresi</li>

        </ul>
        <button class="btn">Şimdi Al</button>
      </div>
      <div class="price-card">
        <h3 class="plan-title">Gold Paket</h3>
        <p class="price">12500₺</p>
        <ul class="features">
          <li>7 Adet Reels</li>
          <li>60 Adet Hikaye Tasarımı</li>
          <li>10 Post Tasarımı</li>
          <li>Reklam Yönetimi</li>
          <li>Tanıtım Filmi</li>
          <li>30 Gün Hizmet Süresi</li>
        </ul>
        <button class="btn">Şimdi Al</button>
      </div>
      <div class="price-card">
        <h3 class="plan-title">One Page Web Sitesi</h3>
        <p class="price">6000₺</p>
        <ul class="features">
          <li>Firmanız için hazırlanmış tek sayfadan oluşan web sitesi</li>
          
        </ul>
        <button class="btn">Şimdi Al</button>
      </div>
      <div class="price-card">
        <h3 class="plan-title">Kurumsal Web Sitesi</h3>
        <p class="price">9800₺</p>
        <ul class="features">
          <li>Firmanız için hazırlanmış Kurumsal web sitesi</li>
          <li>3 Sayfadan Oluşan</li>
          
        </ul>
        <button class="btn">Şimdi Al</button>
      </div>
      <div class="price-card">
        <h3 class="plan-title">E-Ticaret Sitesi</h3>
        <p class="price">16500₺</p>
        <ul class="features">
          <li>Firmanız için hazırlanmış E-Ticaret sitesi</li>
          <li>E-ticaret paneli</li>
          <li>Stok Takibi</li>
          <li>3 Ay destek</li>

          
        </ul>
        <button class="btn">Şimdi Al</button>
      </div>
    </div>
  </section>
  
  );
}
